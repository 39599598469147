<template>
  <EuiContainer>
    <EuiGrid gutters>
      <EuiGridRow>
        <EuiGridColumn>
          <div class="flex items-center">
            <EuiHeading :level="1" bold>{{ $t('signatories.social.index.title') }}</EuiHeading>
            <div class="flex flex-1 justify-end">
            <EuiButton v-if="hasSocialSignerTriggerPermission" variant="raised" color="primary" @click="$router.push({ name: 'signatories.social.create' })">{{ $t('signatories.social.index.create') }}</EuiButton>
            </div>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$i18n.t('signatories.social.index.block.title')">
              <EuiGrid>
                <EuiGridRow>
                  <EuiGridColumn>
                    <EuiHeading :level="4">{{ $tc('signatories.social.selected', signatories.length, { count: signatories.length }) }}</EuiHeading>
                    <EuiTooltip v-if="selectedSignatories.length" style="display: inline-block" :content="$t('button.delete')" placement="top">
                      <template slot="anchor">
                        <EuiButton iconOnly @click="deleteSelected()"><EuiIcon name="delete" color="primary"></EuiIcon></EuiButton>
                      </template>
                    </EuiTooltip>
                  </EuiGridColumn>
                  <EuiGridColumn width="1/3">
                    <EuiInput class="eui-m-cardFilter search" :placeholder="$t('searchBar.placeholder')"
                              v-model.trim="searchInput"
                              @keyup.enter="searchByName()">
                      <template #endEnhancer>
                        <div class="eui-m-cardFilter__clear">
                          <template v-if="searchInput && searchInput.length > 0">
                            <EuiButton iconOnly size="small" color="primary" @click.stop="onClear">
                              <EuiIcon name="close"/>
                            </EuiButton>
                            <div class="eui-m-cardFilter__separator"></div>
                          </template>
                        </div>
                        <EuiButton iconOnly color="primary" size="small" @click.stop="searchByName()">
                          <EuiIcon name="search"/>
                        </EuiButton>
                      </template>
                    </EuiInput>
                  </EuiGridColumn>
                </EuiGridRow>
              </EuiGrid>
            <EuiPagination v-model="pagination.current"
                           class="ef-u-mb-20"
                           :displayText="$t('pagination.text')"
                           :totalPages="pagination.total"
                           :itemsPerPage="searchParams.limit"
                           @update:itemsPerPage="numberChange($event)"
                           @change="goToPage($event)"/>
            <EuiDivider secondary/>
            <FiltersBar secondary
                        :filters='filters'
                        @clear-filters="clearFilters(clearSearchParamsFromFilter, searchSocialSignatories)"
                        @delete-filter="deleteFilterToCompute"
                        @update-filter="updateFilterToCompute"/>
            <EuiDivider secondary/>
              <EuiAdvancedTable v-if="signatories.length > 0" :selectedValue.sync="selectedSignatories" v-model="signatories">
                <template #tableHead>
                    <!-- full name -->
                    <EuiTableHead v-for="item in sortHeaders" :key="item.name">
                      <div class="eui-u-flex">
                        {{ item.name }}
                        <SubMenu :field="item.field"
                                 :name="item.name"
                                 :filterType="item.filter"
                                 :moveColumns="true"
                                 @addFilter="addFilter($event, computeFilter, searchSocialSignatories)"
                                 @moveToLeft="move(item.field, 'left', socialSignatoriesVueOrder, headers, setSocialSignatoriesVueOrderAction)"
                                 @moveToRight="move(item.field, 'right', socialSignatoriesVueOrder, headers, setSocialSignatoriesVueOrderAction)"/>
                      </div>
                    </EuiTableHead>
                    <EuiTableHead />
                </template>
                  <!-- table row -->
                  <EuiATableRow selectable="multiple" slot="tableRow" slot-scope="{ item }">
                    <!-- full name -->
                    <EuiTableCell v-for="(header, index) in sortHeaders" :key="index" @click.native="$router.push({ name: 'signatories.social.edit', params: { userId: item.userId, name: item.lastname } })">
                      {{ item[header.field] }}</EuiTableCell>
                    <EuiTableCell dataType="button" @click.stop>
                      <EuiDropdownMenu v-if="hasSocialSignerTriggerPermission">
                        <template v-slot:anchor>
                          <EuiButton iconOnly color="primary" size="small">
                            <EuiIcon name="more_vert"/>
                          </EuiButton>
                        </template>
                        <EuiList>
                          <EuiListItem :button="true" @click="$router.push({ name: 'signatories.social.edit', params: { userId: item.userId, name: item.lastname } })">
                              {{ $t('button.edit') }}
                          </EuiListItem>
                          <EuiListItem :button="true" @click="deleteSocialSignatory(item.userId)">
                            {{ $t('button.delete') }}
                          </EuiListItem>
                        </EuiList>
                      </EuiDropdownMenu>
                      <EuiButton v-else variant="text" color="primary" @click="$router.push({ name: 'signatories.social.edit', params: { userId: item.userId, name: item.lastname } })">Voir</EuiButton>
                    </EuiTableCell>
                  </EuiATableRow>
              </EuiAdvancedTable>
            <EuiPagination v-model="pagination.current"
                           class="ef-u-mb-20"
                           :displayText="$t('pagination.text')"
                           :totalPages="pagination.total"
                           :itemsPerPage="searchParams.limit"
                           @update:itemsPerPage="numberChange($event)"
                           @change="goToPage($event)"/>
            <div v-if="signatories.length === 0">
              <p>{{ $t('signatories.social.index.noSignatories') }}</p>
            </div>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </EuiContainer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import preferences from '@/utils/preferences';
import SubMenu from '../../../components/table/SubMenu';
import FiltersBar from '../../../components/table/FiltersBar';
import tableFilters from '../../../mixins/tableFilters';
import tableGlobalActions from '../../../mixins/tableGlobalActions';

export default {
  name: 'SignatoriesSocialIndex',
  components: {
    SubMenu,
    FiltersBar,
  },
  mixins: [tableFilters, tableGlobalActions],
  data() {
    return {
      headers: [
        { name: this.$i18n.t('signatories.social.index.table.companyName'), field: 'fullname', filter: ['sortable'] },
        { name: this.$i18n.t('signatories.social.index.table.email'), field: 'email' },
      ],
      signatories: [],
      selectedSignatories: [],
      searchInput: '',
      searchParams: {
        orders: [{ fieldName: 'fullname', orderBy: 'ASC' }],
        params: [],
        offset: 0,
        limit: 20,
      },
      search: {
        isOn: false,
      },
      filters: [],
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.ModuleEdocSign.settingsUsers.pagination,
      socialSignatoriesVueOrder: (state) => state.ModuleEdocSign.preferences.socialSignatoriesVueOrder,
    }),
    hasSocialSignerTriggerPermission() {
      return this.$store.state.ModuleEdocSign.auth.profile.features && this.$store.state.ModuleEdocSign.auth.profile.features.hasSocialSignerTriggerPermission === '1';
    },
    selectAll: {
      get() {
        return this.signatories ? this.selectedSignatories.length === this.signatories.length : false;
      },
      set(value) {
        const selectedSignatories = [];

        if (value) {
          this.signatories.forEach((signatory) => {
            selectedSignatories.push(signatory);
          });
        }
        this.selectedSignatories = selectedSignatories;
      },
    },
    sortHeaders() {
      if (this.socialSignatoriesVueOrder === null) return this.headers;
      return preferences.updateSortedHeaders(this.socialSignatoriesVueOrder, this.headers, this.setSocialSignatoriesVueOrderAction);
    },
  },
  methods: {
    ...mapActions({
      getSocialSignatories: 'ModuleEdocSign/settingsUsers/searchSocialSigners',
      destroy: 'ModuleEdocSign/settingsUsers/destroy',
      setSocialSignatoriesVueOrderAction: 'ModuleEdocSign/preferences/setSocialSignatoriesVueOrder',
      getSocialSignatoriesVueOrderAction: 'ModuleEdocSign/preferences/getSocialSignatoriesVueOrder',
    }),
    computeFilter() {
      this.resetPagination();
      this.filters.forEach((filter) => {
        filter.searchTags.forEach((searchTag, index) => {
          this.searchParams.params[index] = { paramName: 'token', paramValue: searchTag };
        });
        if (filter.sort.length > 0) {
          this.searchParams.orders[0] =
            {
              fieldName: 'fullname',
              orderBy: filter.sort[0],
            };
        }
      });
    },
    clearSearchParamsFromFilter(filter) {
      if (filter.sort.length > 0) {
        this.searchParams.order = { field: 'name', sens: 'ASC' };
      }
      this.searchParams.params.splice(this.searchParams.params.find(param => param.paramValue === filter.searchTags[0]), 1);
    },
    deleteFilterToCompute(field) {
      this.deleteFilter(field, this.clearSearchParamsFromFilter, this.searchSocialSignatories);
    },
    updateFilterToCompute(newFilters) {
      this.updateFilter(newFilters, this.computeFilter, this.searchSocialSignatories);
    },
    numberChange(number) {
      this.searchParams.limit = number;
      this.searchSocialSignatories();
    },
    onSortClick(orderBy, fieldName) {
      this.searchParams.orders = [{ fieldName, orderBy }];
      this.searchSocialSignatories();
    },
    searchSocialSignatories() {
      return this.getSocialSignatories(this.searchParams).then((response) => {
        if (response && response.accounts) {
          this.signatories = response.accounts;
        }
      });
    },
    onClear() {
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [];
      this.searchInput = '';
      this.searchSocialSignatories();
    },
    searchByName() {
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [{
        paramName: 'token', paramValue: this.searchInput,
      }];
      this.searchSocialSignatories();
    },
    deleteSocialSignatory(id) {
      this.destroy(id).then(() => this.searchSocialSignatories());
    },
    deleteSelected() {
      const promises = [];
      for (let i = 0; i < this.selectedSignatories.length; i++) {
        promises.push(this.destroy({ userId: this.selectedSignatories[i].userId }));
      }
      Promise.all(promises)
        .then(() => {
          this.searchSocialSignatories(this.searchParams);
        });
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.getSocialSignatories(this.searchParams)
        .then(() => {
          this.signatories = this.signatories
            .filter((sign) => !this.selected.map((x) => x.eFoliaId)
              .includes(sign.eFoliaId));
        });
    },
  },
  mounted() {
    this.searchSocialSignatories();
    this.getSocialSignatoriesVueOrderAction();
  },
};
</script>
